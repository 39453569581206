/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from "react";
import { QrReader } from "react-qr-reader";
import RoundedButton from "src/Components/Buttons/RoundedButton";

// TODO: replace QR lib with one that can handle a 4k+ char QR code, and replace viewfinder png
const View = () => (
  <img
    src="/images/qrvf.webp"
    alt="viewfinder"
    className="absolute top-0 bottom-0 left-0 right-0 z-30 w-7/12 h-auto p-3 m-auto"
  />
);

const ScanQR = ({ setQr, setOpen, setError }) => {
  const handleScan = useCallback(
    (result, error) => {
      if (result) {
        setQr(result.text);
        setOpen(false);
      }
      if (error) {
        console.log("QR scan error:", error);
        setError({ error: error.name, message: error.message });
      }
    },
    [setQr, setOpen, setError]
  );

  return (
    <div className="flex flex-col justify-center w-full h-full m-auto 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12">
      <div className="relative flex flex-col justify-center w-full h-screen mx-auto text-center">
        <QrReader
          ViewFinder={View}
          containerStyle={{
            position: "relative",
            padding: "0 0 0 0",
            display: "flex",
            justifyContent: "center",
            margin: "auto",
            height: "100%",
            width: "100%",
          }}
          videoStyle={{
            zIndex: 1,
            height: "auto",
            width: "auto",
            margin: "auto",
            position: "absolute",
            right: "0",
            top: "0",
            left: "0",
            bottom: "0",
          }}
          videoContainerStyle={{
            position: "relative",
            height: "100%",
            width: "100%",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            padding: "0 0 0 0",
          }}
          constraints={{ facingMode: "environment" }}
          onResult={handleScan}
        />
        <RoundedButton
          className="absolute left-0 right-0 z-50 w-8/12 py-3 mx-auto mt-4 text-base text-black bg-yellow-500 sm:w-30 bottom-12"
          onClick={() => setOpen(false)}
        >
          Close
        </RoundedButton>
      </div>
    </div>
  );
};

export default ScanQR;
