/* eslint-disable no-unused-vars */
import React from "react";
import { classNames } from "src/Hooks/util";

interface TextFieldWithButtonProps {
  className?: string;
  handleOnClick: () => void;
  label?: string;
  type: string;
  placeholder?: string;
  handleOnInputChange: (value) => void;
  inputValue?: any;
  buttonName: string;
  warning?: boolean;
  disabled?: boolean;
  min?: number;
  max?: number;
}

const TextFieldWithButton: React.FC<TextFieldWithButtonProps> = ({
  className,
  handleOnClick,
  label,
  type,
  placeholder,
  handleOnInputChange,
  inputValue,
  buttonName,
  warning,
  disabled,
  ...rest
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      type === "number" ? parseFloat(e.target.value) : e.target.value;
    handleOnInputChange(value);
  };

  return (
    <div className={classNames(`${className} w-full`)}>
      {label && (
        <p className="mb-1 text-sm font-light text-left text-gray-800 dark:text-gray-500">
          {label}
        </p>
      )}
      <div className="flex w-full mt-2 rounded-lg flex-grow-default">
        <div className="relative flex items-stretch flex-grow-default focus-within:z-10">
          <input
            type={type}
            className="block w-full p-3 mx-auto text-base placeholder-gray-600 bg-gray-200 border-none without-ring focus:bg-gray-300 rounded-l-xl focus:ring-darkmode-600 dark:bg-darkmode-700 focus:ring-1 disabled:opacity-50 disabled:cursor-not-allowed focus:text-gray-900 dark:placeholder-gray-600 dark:text-gray-500 dark:focus:text-gray-500 focus:placeholder-gray-600"
            placeholder={placeholder}
            onChange={handleChange}
            value={inputValue ? inputValue.toString() : ""}
            disabled={disabled}
            {...rest}
          />
        </div>
        <button
          type="button"
          className={classNames(
            warning ? "dark:text-red-600" : "dark:text-yellow-500",
            "relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-xl px-3 py-2 text-sm font-medium dark:bg-darkmode-700 text-gray-900 without-ring hover:bg-gray-50 dark:disabled:text-opacity-50 disabled:cursor-not-allowed"
          )}
          onClick={handleOnClick}
          disabled={disabled}
        >
          {buttonName}
        </button>
      </div>
    </div>
  );
};

export default TextFieldWithButton;
