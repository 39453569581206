/* global chrome */
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as backend from "bitmask-core";

import PageWrapper2 from "src/Components/Layout/Wrappers/PageWrapper2";
import { Location } from "src/types";
import { delHash, delay, setHash } from "src/Hooks/util";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import lib, { isExtension } from "src/lib";
import { defaultLocation } from "src/Components/Navbar/Menu";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import TextFieldWithButton from "src/Components/Inputs/TextFieldWithButton";
import Copy from "src/Components/Icons/Copy";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";

const Settings = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  let location = useLocation() as Location;
  const [retrieveSeedPassword, setRetrieveSeedPassword] = useState("");
  const [deleteWalletPassword, setDeleteWalletPassword] = useState("");
  const [walletVault, setVault] = useState<backend.bitcoin.Vault>();
  const [retrieveSeedError, setRetrieveSeedError] = useState(false);
  const [deleteWalletError, setDeleteWalletError] = useState(false);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const network = window.localStorage.getItem("network");

  const handleRetrieveSeed = async () => {
    setRetrieveSeedError(false);
    const encryptedDescriptors = window.localStorage.getItem(
      `${network}Descriptor`
    );
    try {
      if (!encryptedDescriptors) {
        throw new Error("No descriptors found");
      }
      const hash = backend.bitcoin.hashPassword(retrieveSeedPassword);
      const vault = await backend.bitcoin.decryptWallet(
        hash,
        encryptedDescriptors
      );
      await setHash(hash, vault.public.xpubkh);
      setVault(vault);
    } catch (err) {
      setRetrieveSeedError(true);
    }
  };

  const handleLockWallet = async () => {
    localStorage.setItem("lockWallet", "true");
    await lib.storageDel(["vault"]);
    delHash();
    window.history.replaceState({}, "", window.location.href);
    location = defaultLocation;
    navigate("/signin", { replace: true });
  };

  const handleDeleteWallet = async () => {
    setDeleteWalletError(false);
    const encryptedDescriptors = window.localStorage.getItem(
      `${network}Descriptor`
    );
    try {
      if (!encryptedDescriptors) {
        throw new Error("No descriptors found");
      }

      const hash = backend.bitcoin.hashPassword(deleteWalletPassword);

      const vault = await backend.bitcoin.decryptWallet(
        hash,
        encryptedDescriptors
      );

      await setHash(hash, vault.public.xpubkh);

      if (vault) {
        if (isExtension) {
          localStorage.clear();
          lib.storageClear();
          window.history.replaceState({}, "", window.location.href);
          location = defaultLocation;
          await chrome.runtime.sendMessage({ call: "reload_script_and_tabs" });
          await delay(1000);
          chrome.runtime.reload();
        } else {
          localStorage.clear();
          lib.storageClear();
          window.history.replaceState({}, "", window.location.href);
          location = defaultLocation;
          navigate("/");
        }
      }
    } catch (err) {
      setDeleteWalletError(true);
      console.error(err);
    }
  };

  return (
    <PageWrapper2
      className="h-auto"
      title="Settings"
      handlePageBack={() => navigate("/")}
    >
      <div className="flex flex-col justify-center w-full mx-auto xs:p-2 md:w-10/12 pb-9 sm:p-12">
        <div className="flex flex-col w-full space-y-4 xs:space-y-6">
          <div className="flex flex-col">
            <p className="w-full m-auto text-base font-normal text-left text-black sm:text-lg dark:text-white">
              Retrieve seed phrase
            </p>
            <p className="w-full m-auto text-left font-thin text-xs xs:text-sm sm:text-base dark:text-gray-500 mt-1.5 mb-3 xs:mt-3 xs:mb-4 text-black">
              If you never wrote down, or lost your mnemonic seed phrase. you
              can recover it using your password.
            </p>
            {walletVault ? (
              <div className="flex flex-col">
                <div className="w-full m-auto grid grid-cols-3 py-1.5 text-black dark:text-white gap-1.5 sm:gap-3">
                  {walletVault.mnemonic.split(" ").map((phrase, i) => (
                    <div
                      key={phrase}
                      className="border-1 border-gray-400 dark:border-newdarkmode-600 border-opacity-50 text-sm sm:text-base rounded-lg p-1 xs:p-1.5 text-left"
                    >
                      {i + 1}. {phrase}
                    </div>
                  ))}
                </div>
                <button
                  type="button"
                  title="Copy wallet address"
                  onClick={() => {
                    navigator.clipboard.writeText(walletVault.mnemonic);
                    setOpenCopyModal(true);
                  }}
                  className="rounded-lg shadow-sm my-3 px-2 py-0.5 sm:py-1 flex bg-gray-300 dark:bg-newdarkmode-800 dark:bg-opacity-75 font-thin cursor-pointer text-sm text-center w-full m-auto flex-nowrap text-gray-900 hover:bg-gray-300 dark:text-gray-500 dark:hover:text-gray-400"
                >
                  <p className="flex justify-center w-auto m-auto text-center truncate">
                    Copy mnemonic seed phrase
                  </p>
                  <Copy className="flex flex-none w-6 h-6" />
                </button>
                <RoundedButton
                  className="text-base bg-yellow-500 border-2 border-yellow-500 m-auto text-newdarkmode-800 mt-1.5 min-w-64"
                  onClick={() => {
                    setVault(undefined);
                    setRetrieveSeedPassword("");
                  }}
                >
                  Clear
                </RoundedButton>
              </div>
            ) : (
              <TextFieldWithButton
                handleOnInputChange={(value) => {
                  setRetrieveSeedError(false);
                  setRetrieveSeedPassword(value);
                }}
                inputValue={retrieveSeedPassword}
                handleOnClick={async () => handleRetrieveSeed()}
                type="password"
                placeholder="Enter Password"
                buttonName="Retrieve"
              />
            )}
            {retrieveSeedError && (
              <p className="w-full m-auto mt-3 text-sm text-center text-black dark:text-white sm:text-base">
                Error: Incorrect password
              </p>
            )}
          </div>
          <div className="flex flex-col">
            <p className="w-full m-auto text-base font-normal text-left text-black sm:text-lg dark:text-white">
              Lock wallet
            </p>
            <p className="w-full m-auto text-left font-thin text-xs xs:text-sm sm:text-base dark:text-gray-500 mt-1.5 mb-3 xs:mt-3 xs:mb-4 text-black">
              Deletes wallets vault and requires password to recreate sensitive
              data and use functionality.
            </p>
            <RoundedButton
              className="mr-auto text-base text-white bg-newdarkmode-700"
              onClick={() => handleLockWallet()}
            >
              <div className="flex flex-row px-2 py-1 m-auto">
                <LockClosedIcon className="h-4 w-4 mr-1.5 my-auto" />{" "}
                <p className="my-auto">Lock Wallet</p>
              </div>
            </RoundedButton>
          </div>
          <div className="flex flex-col">
            <p className="w-full m-auto text-base font-normal text-left text-black sm:text-lg dark:text-white">
              Remove wallet from browser
            </p>
            <p className="w-full m-auto text-left font-thin text-xs xs:text-sm sm:text-base dark:text-gray-500 mt-1.5 mb-3 xs:mt-3 xs:mb-4 text-black">
              Deletes all wallet data from device, this cannot be undone.
              Proceed with caution, you may lose assets or access to wallet if
              data is not properly backed up.
            </p>
            <TextFieldWithButton
              handleOnInputChange={(value) => {
                setDeleteWalletError(false);
                setDeleteWalletPassword(value);
              }}
              inputValue={deleteWalletPassword}
              handleOnClick={() => handleDeleteWallet()}
              type="password"
              placeholder="Enter Password"
              buttonName="Delete"
              warning
            />
            {deleteWalletError && (
              <p className="w-full m-auto mt-3 text-sm text-center text-black dark:text-white sm:text-base">
                Error: Incorrect password
              </p>
            )}
          </div>
        </div>
      </div>
      <CopiedToClipboard open={openCopyModal} setOpen={setOpenCopyModal} />
    </PageWrapper2>
  );
};

export default Settings;
